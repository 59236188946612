<template>
  <b-container class="mt-4">
    <b-modal id="singleDeletion" title="Dikkat!" @ok="deleteSingleItem">
      <p class="my-4">Raporu silmek istediğinizden emin misiniz?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Gelişmiş Raporlar'" class="mr-2 text-white"></label>
        <b-button variant="primary" to="/advancedReport"
          >Rapor Oluştur</b-button
        >
      </template>
      <div v-if="getReportItems.length == 0">
        <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
      </div>
      <div v-else>
        <b-alert show>
          Raporları yazdırmak için <b>Rapor Başlıklarına</b> tıklayabilirsiniz.
        </b-alert>
        <b-table :fields="fields" :items="getReportItems" striped responsive>
          <template #cell(id)="id">
            <b-badge variant="secondary">
              {{ id.index + 1 }}
            </b-badge>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'title' ? '80%' : '10%' }"
            />
          </template>
          <template #cell(type)="row">
            {{ getReportItems[row.index].type | translateReport }}
          </template>
          <template #cell(title)="row">
            <b-link
              variant="secondary"
              @click="printReport(getReportItems[row.index].id)"
            >
              {{ getReportItems[row.index].title }}
            </b-link>
          </template>
          <template #cell(tools)="row">
            <b-button-group>
              <download-excel
                :id="getReportItems[row.index].id.toString()"
                class="btn btn-success"
                :fetch="() => getData(getReportItems[row.index].id)"
                :fields="reportFields"
                :worksheet="reportTitle"
                name="filename.xls"
              >
                <b-icon-file-earmark-excel></b-icon-file-earmark-excel>
              </download-excel>
              <b-tooltip :target="getReportItems[row.index].id.toString()">Excel al</b-tooltip>

              <b-button
                size="sm"
                :to="{
                  path: 'advancedReport/' + row.item.id,
                  params: {
                    id: row.item.id,
                  },
                }"
              >
                <b-icon-pencil></b-icon-pencil>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                v-b-modal.singleDeletion
                @click="singleDeletingItem = getReportItems[row.index].id"
              >
                <b-icon-trash></b-icon-trash>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  bloodOptions,
  educationOptions,
  genderOptions,
  maritalOptions,
  nationalityOptions,
  reasonOptions,
  religionOptions,
} from "../../../store/constants/personel";
import eliminationReason from "@/store/constants/eliminationReason";
import roleOptions from "../../../store/constants/roleOptions";
import PMSReportHelper from "../../../store/helpers/PMSReportHelper";
import JsonExcel from "vue-json-excel";
export default {
  name: "AdvancedReports",
  components: {
    "download-excel": JsonExcel,
  },
  computed: {
    ...mapGetters(["getReportItems", "getManagementItems"]),
  },
  data() {
    return {
      fields: [
        { label: "Sıra", key: "id" },
        { label: "Raporun Başlığı", key: "title" },
        { label: "Raporun Türü", key: "type" },
        { label: "İşlemler", key: "tools" },
      ],
      reportFields: {},
      reportTitle: "",
      singleDeletingItem: null,
    };
  },
  mounted() {
    this.$store.dispatch("initReportItems");
    this.$store.dispatch("initManagement");
    this.$store.dispatch("getClubNames");
    this.$store.dispatch("initCompetitionNames");
  },
  filters: {
    translateReport: function (value) {
      if (!value) return "Hata";
      value = value.toString();
      switch (value) {
        case "personel":
          return "Personel";
        case "club":
          return "Kulüp";
        case "competition":
          return "Yarışma";
        case "competitionGroup":
          return "Yarışma Grubu";
        case "match":
          return "Karşılaşma";
        default:
          return "Hata";
      }
    },
  },
  methods: {
    printReport(id) {
      this.$store.dispatch("printReport", id).then((res) => {
        switch (res.result.type) {
          case "personel": {
            PMSReportHelper.getPdfItemsFromServer(
              "/advancedPersonelReport",
              PMSReportHelper.getSelectedPersonelColumns(
                false,
                JSON.parse(res.result.json)
              )
            ).then((result) => {
              result = this.localizePersonelReport(result);
              PMSReportHelper.generateHTML(
                res.result.title,
                this.getTableNames(
                  res.result.order.split(","),
                  JSON.parse(res.result.json),
                  res.result.type
                ),
                result.map((e) =>
                  res.result.order.split(",").map((o) => (e[o] ? e[o] : ""))
                ),
                res.result.orientation
              );
            });
            break;
          }
          case "club": {
            PMSReportHelper.getPdfItemsFromServer(
              "/advancedClubReport",
              PMSReportHelper.getSelectedClubColumns(
                false,
                JSON.parse(res.result.json)
              )
            ).then((result) => {
              result = this.localizeClubReport(result);
              PMSReportHelper.generateHTML(
                res.result.title,
                this.getTableNames(
                  res.result.order.split(","),
                  JSON.parse(res.result.json),
                  res.result.type
                ),
                result.map((e) => res.result.order.split(",").map((o) => e[o]))
              );
            });
            break;
          }
          case "competition": {
            PMSReportHelper.getPdfItemsFromServer(
              "/advancedCompetitionReport",
              PMSReportHelper.getSelectedCompetitionColumns(
                false,
                JSON.parse(res.result.json)
              )
            ).then((result) => {
              result = this.localizeCompetitionReport(result);
              PMSReportHelper.generateHTML(
                res.result.title,
                this.getTableNames(
                  res.result.order.split(","),
                  JSON.parse(res.result.json),
                  res.result.type
                ),
                result.map((e) => res.result.order.split(",").map((o) => e[o]))
              );
            });
            break;
          }
          case "competitionGroup": {
            PMSReportHelper.getPdfItemsFromServer(
              "/advancedCompetitionGroupReport",
              PMSReportHelper.getSelectedCompetitionGroupColumns(
                false,
                JSON.parse(res.result.json)
              )
            ).then((result) => {
              result = this.localizeCompetitionGroupReport(result);
              PMSReportHelper.generateHTML(
                res.result.title,
                this.getTableNames(
                  res.result.order.split(","),
                  JSON.parse(res.result.json),
                  res.result.type
                ),
                result.map((e) => res.result.order.split(",").map((o) => e[o]))
              );
            });
            break;
          }
          case "match": {
            PMSReportHelper.getPdfItemsFromServer(
              "/advancedMatchReport",
              PMSReportHelper.getSelectedMatchColumns(
                false,
                JSON.parse(res.result.json)
              )
            ).then((result) => {
              result = this.localizeMatchReport(result);
              PMSReportHelper.generateHTML(
                res.result.title,
                this.getTableNames(
                  res.result.order.split(","),
                  JSON.parse(res.result.json),
                  res.result.type
                ),
                result.map((e) => res.result.order.split(",").map((o) => e[o]))
              );
            });
            break;
          }
        }
      });
    },
    async getDataFromServer(res){
      const endpoints={
        "personel":{
          endpoint:"/advancedPersonelReport",
          function:"getSelectedPersonelColumns"
        },
        "club":{
          endpoint:"/advancedClubReport",
          function:"getSelectedClubColumns"
        },
        "competition":{
          endpoint:"/advancedCompetitionReport",
          function:"getSelectedCompetitionColumns"
        },
        "competitionGroup":{
          endpoint:"/advancedCompetitionGroupReport",
          function:"getSelectedCompetitionGroupColumns"
        },
        "match":{
          endpoint:"/advancedMatchReport",
          function:"getSelectedMatchColumns"
        }
      }
      this.reportFields = this.getTableNames(
              res.result.order.split(","),
              JSON.parse(res.result.json),
              res.result.type
            ).reduce((obj, e, index) => {
              obj[e] = res.result.order.split(",")[index];
              return obj;
            }, {});
            this.reportTitle = res.result.title;
            const lastRes = (
              await PMSReportHelper.getPdfItemsFromServer(
                endpoints[res.result.type].endpoint,
                PMSReportHelper[endpoints[res.result.type].function](
                  false,
                  JSON.parse(res.result.json)
                )
              )
            ).map((e) => {
              const row = {};
              this.getTableNames(
                res.result.order.split(","),
                JSON.parse(res.result.json),
                res.result.type
              ).forEach((element, index) => {
                row[res.result.order.split(",")[index]] =
                  e[res.result.order.split(",")[index]] ?? "";
              });
              return row;
            });
            
            return lastRes;
    },
    async getData(id) {
      
      return this.$store.dispatch("printReport", id).then(async (res) => {
        return this.getDataFromServer(res)
        // switch (res.result.type) {
        //   case "personel": {
            
        //    return this.getDataFromServer("/advancedPersonelReport",res)
        //   }
        //   case "club": {
        //     return this.getDataFromServer( "/advancedClubReport",res)
        //   }
        //   case "competition": {
        //     PMSReportHelper.getPdfItemsFromServer(
        //       "/advancedCompetitionReport",
        //       PMSReportHelper.getSelectedCompetitionColumns(
        //         false,
        //         JSON.parse(res.result.json)
        //       )
        //     ).then((result) => {
        //       result = this.localizeCompetitionReport(result);
        //       PMSReportHelper.generateHTML(
        //         res.result.title,
        //         this.getTableNames(
        //           res.result.order.split(","),
        //           JSON.parse(res.result.json),
        //           res.result.type
        //         ),
        //         result.map((e) => res.result.order.split(",").map((o) => e[o]))
        //       );
        //     });
        //     break;
        //   }
        //   case "competitionGroup": {
        //     PMSReportHelper.getPdfItemsFromServer(
        //       "/advancedCompetitionGroupReport",
        //       PMSReportHelper.getSelectedCompetitionGroupColumns(
        //         false,
        //         JSON.parse(res.result.json)
        //       )
        //     ).then((result) => {
        //       result = this.localizeCompetitionGroupReport(result);
        //       PMSReportHelper.generateHTML(
        //         res.result.title,
        //         this.getTableNames(
        //           res.result.order.split(","),
        //           JSON.parse(res.result.json),
        //           res.result.type
        //         ),
        //         result.map((e) => res.result.order.split(",").map((o) => e[o]))
        //       );
        //     });
        //     break;
        //   }
        //   case "match": {
        //     PMSReportHelper.getPdfItemsFromServer(
        //       "/advancedMatchReport",
        //       PMSReportHelper.getSelectedMatchColumns(
        //         false,
        //         JSON.parse(res.result.json)
        //       )
        //     ).then((result) => {
        //       result = this.localizeMatchReport(result);
        //       PMSReportHelper.generateHTML(
        //         res.result.title,
        //         this.getTableNames(
        //           res.result.order.split(","),
        //           JSON.parse(res.result.json),
        //           res.result.type
        //         ),
        //         result.map((e) => res.result.order.split(",").map((o) => e[o]))
        //       );
        //     });
        //     break;
        //   }
        // }
      });
    },
    getTableNames(fields, table, type) {
      const elems = [];
      switch (type) {
        case "personel": {
          fields.forEach((element) => {
            elems.push(
              ...(table.personel.columns.filter(
                (e) => e.columnName == element
              ) ?? [])
            );
            elems.push(
              ...(table.branch.columns.filter((e) => e.columnName == element) ??
                [])
            );
            elems.push(
              ...(table.education.columns.filter(
                (e) => e.columnName == element
              ) ?? [])
            );
            elems.push(
              ...(table.system.columns.filter((e) => e.columnName == element) ??
                [])
            );
            elems.push(
              ...(table.contact.columns.filter(
                (e) => e.columnName == element
              ) ?? [])
            );
          });
          break;
        }
        case "club": {
          fields.forEach((element) => {
            elems.push(
              ...(table.club.columns.filter((e) => e.columnName == element) ??
                [])
            );
          });
          break;
        }
        case "competition": {
          fields.forEach((element) => {
            elems.push(
              ...(table.competitions.columns.filter(
                (e) => e.columnName == element
              ) ?? [])
            );
          });
          break;
        }
        case "competitionGroup": {
          fields.forEach((element) => {
            elems.push(
              ...(table.competitionGroups.columns.filter(
                (e) => e.columnName == element
              ) ?? [])
            );
          });
          break;
        }
        case "match": {
          fields.forEach((element) => {
            elems.push(
              ...(table.matches.columns.filter(
                (e) => e.columnName == element
              ) ?? [])
            );
          });
          break;
        }
      }
      return elems.map((e) => e.title);
    },
    deleteSingleItem() {
      this.$store.dispatch("deleteReportItem", [this.singleDeletingItem]);
    },
    dateFormatter(date) {
      return date
        ? date.includes("T")
          ? date.split("T")[1].split(".")[0].replace(":", ".")
          : date.split("-").reverse().join(".")
        : "";
    },
    localizeCompetitionGroupReport(res) {
      const result = res;
      result.forEach((el) => {
        el.formOfParticipation
          ? (el.formOfParticipation = roleOptions.find(
              (e) => e.value == el.formOfParticipation
            ).text)
          : "";
      });
      return result;
    },
    localizeMatchReport(res) {
      const result = res;
      result.forEach((el) => {
        el.matchDate ? (el.matchDate = this.dateFormatter(el.matchDate)) : "";
        el.eliminationReason
          ? (el.eliminationReason = eliminationReason.find(
              (e) => e.value == el.eliminationReason
            ).text)
          : "";
      });

      return result;
    },

    localizeClubReport(res) {
      const result = res;
      result.forEach((el) => {
        el.foundationDate
          ? (el.foundationDate = this.dateFormatter(el.foundationDate))
          : "";
        el.closingDate
          ? (el.closingDate = this.dateFormatter(el.closingDate))
          : "";
        el.streamWaterLastAccreditedYear
          ? (el.streamWaterLastAccreditedYear = this.dateFormatter(
              el.streamWaterLastAccreditedYear
            ))
          : "";
        el.stillWaterLastAccreditedYear
          ? (el.stillWaterLastAccreditedYear = this.dateFormatter(
              el.stillWaterLastAccreditedYear
            ))
          : "";
        el.seaKayakingLastAccreditedYear
          ? (el.seaKayakingLastAccreditedYear = this.dateFormatter(
              el.seaKayakingLastAccreditedYear
            ))
          : "";
        el.streamWaterLastAccreditedYear
          ? (el.streamWaterLastAccreditedYear = this.dateFormatter(
              el.streamWaterLastAccreditedYear
            ))
          : "";
      });
      return result;
    },
    localizeCompetitionReport(res) {
      const result = res;
      result.forEach((el) => {
        el.category
          ? (el.category = this.getManagementItems
              .filter((e) => {
                return el.category.split(";").find((k) => k == e.id);
              })
              .map((e) => e.title)
              .join(", "))
          : "";
        el.location
          ? (el.location = [
              { text: "Ulusal", value: "national" },
              { text: "Uluslararası", value: "international" },
            ].find((e) => e.value == el.location).text)
          : "";
        el.startingDate
          ? (el.startingDate = this.dateFormatter(el.startingDate))
          : "";
        el.endingDate
          ? (el.endingDate = this.dateFormatter(el.endingDate))
          : "";
      });
      return result;
    },
    localizePersonelReport(res) {
      const result = res;
      result.forEach((el) => {
        el.nationality
          ? (el.nationality = nationalityOptions.find(
              (e) => e.value == el.nationality
            ).text)
          : "";
        el.maritalStatus
          ? (el.maritalStatus = maritalOptions.find(
              (e) => e.value == el.maritalStatus
            ).text)
          : "";
        el.religion
          ? (el.religion = religionOptions.find(
              (e) => e.value == el.religion
            ).text)
          : "";
        el.bloodGrup
          ? (el.bloodGrup = bloodOptions.find(
              (e) => e.value == el.bloodGrup
            ).text)
          : "";
        el.reasonForGiven
          ? (el.lreasonForGiven = reasonOptions.find(
              (e) => e.value == el.reasonForGiven
            ).text)
          : "";
        el.gender
          ? (el.gender = genderOptions.find((e) => e.value == el.gender).text)
          : "";
        el.sportState
          ? (el.sportState = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.sportState).text)
          : "";
        el.sportRefereeState
          ? (el.sportRefereeState = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.sportRefereeState).text)
          : "";
        el.trainerState
          ? (el.trainerState = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.trainerState).text)
          : "";
        el.provincialRepresentative
          ? (el.provincialRepresentative = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.provincialRepresentative).text)
          : "";
        el.assemblyMember
          ? (el.assemblyMember = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.assemblyMember).text)
          : "";
        el.personel
          ? (el.personel = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.personel).text)
          : "";
        el.manager
          ? (el.manager = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.manager).text)
          : "";
        el.educationStatus
          ? (el.educationStatus = educationOptions.find(
              (e) => e.value == e.educationStatus
            ).text)
          : "";
      });
      return result;
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>